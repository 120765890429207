import React from 'react'

const Voice = () => {
 return (
    <div className="voice-container">
      <div className="voice-header">
        <h2 className="text-center">Voice</h2>
      </div>
      <div className="voice-body text-center">
      <div className="btn-group">
        <a href="images/voice/St.Anthony'sVoiceDecember2024-1.pdf" title='Download' target='blank'>St.Anthony's Voice - December</a>
        </div>
        {/* <div className="btn-group">
        <a href="images/voice/St Anthony's Voice October 2024-1_241022_094458.pdf" title='Download' target='blank'>St.Anthony's Voice - October</a>
        </div> */}
      </div>
    </div>
  )
}

export default Voice